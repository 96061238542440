// Import app styles
import "./assets/styles/main.scss";

// Add assets to /dist
import "./assets/favicon.ico";
import "./assets/default-image.jpg";

import "./assets/map/bar.png";
import "./assets/map/cafe.png";
import "./assets/map/liquor.png";
import "./assets/map/music.png";
import "./assets/map/restaurant.png";
import "./assets/map/shopping-cart.png";
import "./assets/map/user.png";
import "./assets/map/selected.png";

// Add polyfills
import "core-js/es/promise";
import "core-js/es/array";

// Register service worker
// if ("serviceWorker" in navigator) {
//     window.addEventListener("load", () => {
//         navigator.serviceWorker.register("/service-worker.js");
//     });
// }
